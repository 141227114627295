@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css');

:root {
	--header-dimension: 150px;
	--gapping: 40px;
	--accent: #4361ee;
}

body, html, body {
	width: 100%;
	height: 100%;
}
body {
	font-family: 'Roboto', sans-serif;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ddd;
	font-size: 16px;
}
* {
	box-sizing: border-box;
	flex-shrink: 0;
}

.a4 {
	width: 21cm;
	height: 29.7cm;
	overflow: hidden;
	background: white;
	position: relative;
	box-shadow: 0 0 5px 1px rgb(0 0 0 / 25%);
	page-break-after: always;
}

.a4wrap {
	display: flex;
	flex-direction: column;
}

@media screen {
	.a4wrap {
		overflow: auto;
		width: 100%;
		margin: 0 auto;
		padding: 50px;
		gap: var(--gapping);
	}

	.a4 {
		margin: 0 auto;
	}
}

a.ul {
	text-decoration: underline;
}

@media print {

	body * {
		visibility: hidden;
	}

	.preheader {
		display: none!important;
		width: 0!important;
		height: 0!important;
	}

	.a4, .a4 * {
		visibility: visible;
	}

	a {
		text-decoration: none!important;
	}

}


.align {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: var(--gapping);
	gap: var(--gapping);
}

.header {
	display: flex;
	height: var(--header-dimension);
	gap: var(--gapping);
}

.main {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.photo-block {
	display: flex;
	height: var(--header-dimension);
	width: var(--header-dimension);
	border-radius: var(--header-dimension);
	border: 5px solid var(--accent);
	overflow: hidden;
	position: relative;
	justify-content: center;
}

.photo-block img {
	width: 100%;
}

.header-remain {
	display: flex;
	flex: 1;
	justify-content: space-between;
	flex-direction: column;
}

.name {
	font-size: 40px;
}

.signature {
	font-size: 25px;
}

.contact {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.section ul {
	padding: 0 0 0 20px;
	margin: 0;
}

a {
	text-decoration: inherit;
	color: var(--accent);
}

a:hover {
	text-decoration: underline;
}

.main {
	gap: 20px;
}

i.fa-solid, i.fa-brands, i.fa-regular, i.fas, i.far {
	margin-right: 5px;
	margin-left: 5px;
}

.fa-arrow-up-right-from-square {
	font-size: 0.75em;
}

.section .title {
	background-color: var(--accent);
	color: white;
	padding: 5px 10px;
	font-size: 20px;
}

.section .content {
	padding: 20px 0 0 20px;
}

.flextable {
	display: flex;
	justify-content: center;
}

.flextable > * {
	display: flex;
	flex: 1;
	justify-content: space-between;
}

#generate {
	display: flex;
	font-size: 14px;
	position: absolute;
	top: 0;
	opacity: 0.5;
	background: rgba(255,255,255,0.7);
	padding: 10px;
}

@media screen {
	#generate {
		display: none;
	}
}

.missing-tech {
	text-align: center;
	font-size: 20px;
}

#root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.preheader .scrollbar {
    display: flex;
    gap: 20px;
    margin: auto;
}

.preheader {
	padding: 50px 20px 20px 20px;
    overflow: auto;
    display: flex;
    padding-bottom: 10px;
}

.preheader .btn {
	display: flex;
	align-items: center;
	padding: 15px 20px;
	background-color: white;
	box-shadow: 0 0 5px 1px rgb(0 0 0 / 25%);
	gap: 10px;
	font-size: 20px;
}

.preheader .btn:hover {
	cursor: pointer;
}
